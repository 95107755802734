import styled from 'styled-components';
import theme from '@shared/assets/theme/globalTheme';
export const Container = styled.div.withConfig({
  displayName: "Nodestyle__Container",
  componentId: "sc-f8ps02-0"
})(["", " outline-color:", ";background-color:", ";span{", " ", " color:", ";}@media (max-width:", "){", "}"], {
  "position": "relative",
  "height": "1rem",
  "width": "1rem",
  "borderRadius": "9999px",
  "outlineStyle": "solid",
  "outlineWidth": "3px",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, ({
  $isActive
}) => $isActive ? theme.colors.primary[50] : 'rgba(229, 229, 229, 1)', ({
  $isActive
}) => $isActive ? theme.colors.primary[40] : 'white', {
  "position": "absolute",
  "left": "50%",
  "top": "1.25rem",
  "--tw-translate-x": "-50%",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "whiteSpace": "nowrap",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, {
  "fontSize": "21px",
  "lineHeight": "1.5rem"
}, ({
  $isActive
}) => $isActive ? theme.colors.grey[40] : theme.colors.grey[20], ({
  theme
}) => theme.breakpoint.md, {
  "outlineWidth": "2px"
});