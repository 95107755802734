import { FC, Fragment, useEffect } from 'react';
import { useWindowSize } from '@shared/utils/hooks';
import useLockedBody from '@shared/utils/hooks/useLockedBody.utils';
import breakpoint from '../../../../../shared/assets/breakpoint.json';
import AlertModal from './AlertModal';
import { ButtonWrapper, CustomButton } from './AlertModal.style';
interface Props {
  isOpen: boolean;
}
const SkipModalLogic: FC<Props> = ({
  isOpen
}: Props) => {
  const {
    width
  } = useWindowSize();
  const [_locked, setLocked] = useLockedBody(false, 'root');
  const isMobile = width < breakpoint.md;
  useEffect(() => {
    setLocked(isOpen);
  }, [isOpen]);
  const renderDescription = () => {
    return isMobile ? <Fragment>
                ถ้ารู้แล้วว่าชอบอาหารแบบไหน
                <br />
                กลับมาบอกข้าวหอมได้ตลอดนะคะ
            </Fragment> : 'ถ้ารู้แล้วว่าชอบอาหารแบบไหน กลับมาบอกข้าวหอมได้ตลอดนะคะ';
  };
  const renderBottomContent = () => {
    return <ButtonWrapper>
                <CustomButton type="default" onClick={() => window.location.href = '/'}>
                    กลับหน้าหลัก
                </CustomButton>
                <CustomButton type="primary" onClick={() => window.location.href = '/profile/interesting'}>
                    ไปหน้าความสนใจ
                </CustomButton>
            </ButtonWrapper>;
  };
  return <AlertModal isOpen={isOpen} imageUrl={'/images/interesting/interesting-skip.png'} title={'ไม่เป็นไรนะ'} description={renderDescription()} bottomContent={renderBottomContent()} />;
};
export default SkipModalLogic;