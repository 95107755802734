import Image from 'next/image';
import { Collapse } from 'antd';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "FooterMobilestyle__Container",
  componentId: "sc-1ya5495-0"
})(["", ""], {
  "position": "relative",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(23 23 23 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingBottom": "1rem",
  "paddingTop": "3rem"
});
export const Title = styled.div.withConfig({
  displayName: "FooterMobilestyle__Title",
  componentId: "sc-1ya5495-1"
})(["", " :hover{", "}"], {
  "marginBottom": "0.5rem",
  "fontSize": "28px",
  "fontWeight": "700",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
});
export const CollapseArrow = styled(Image).withConfig({
  displayName: "FooterMobilestyle__CollapseArrow",
  componentId: "sc-1ya5495-2"
})(["", " rotate:", ";transition:all 0.3s ease-in;"], {
  "width": "1rem"
}, ({
  $isActive
}) => $isActive ? '0deg' : '180deg');
export const CustomCollapse = styled(Collapse).withConfig({
  displayName: "FooterMobilestyle__CustomCollapse",
  componentId: "sc-1ya5495-3"
})([".ant-collapse-header,.ant-collapse-content-box{", "}"], {
  "padding": "0px !important"
});
export const SubTitle = styled.div.withConfig({
  displayName: "FooterMobilestyle__SubTitle",
  componentId: "sc-1ya5495-4"
})(["", ""], {
  "marginBottom": "0.5rem",
  "fontSize": "1.5rem",
  "lineHeight": "2rem",
  "fontWeight": "300",
  "--tw-text-opacity": "1",
  "color": "rgb(206 206 206 / var(--tw-text-opacity))"
});
export const ContainerDivider = styled.div.withConfig({
  displayName: "FooterMobilestyle__ContainerDivider",
  componentId: "sc-1ya5495-5"
})(["", ""], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(23 23 23 / var(--tw-bg-opacity))",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
export const Divider = styled.div.withConfig({
  displayName: "FooterMobilestyle__Divider",
  componentId: "sc-1ya5495-6"
})(["", " opacity:0.2;"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "height": "1px",
  "width": "100%",
  "maxWidth": "1314px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(167 167 167 / var(--tw-bg-opacity))"
});