import { FC } from 'react';
import { useRouter } from 'next/router';
import { BrandDesc, BrandLogo, Container, Mail, Phone } from './Contact.style';
interface Props {
  onClickTracker: (title: string) => void;
}
const Contact: FC<Props> = ({
  onClickTracker
}: Props) => {
  const router = useRouter();
  return <Container>
            <BrandLogo src="/images/menubar/icon-brand-desktop.png" alt="cpbrand" onClick={() => {
      router.push('/');
      onClickTracker('logo_cpbrand');
    }} width={176} height={46} priority />
            <BrandDesc>
                {`ผลิตภัณฑ์อาหารซีพี "จากใจสู่จาน" ศูนย์ผู้บริโภค ซีพีเอฟ`}
            </BrandDesc>
            <Phone href="tel:6628008000" onClick={() => onClickTracker('phone_number')} aria-label="Phone">
                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <g id="ion:call">
                        <path id="Vector" d="M12.2186 15C11.6086 15 10.7517 14.7793 9.46861 14.0625C7.9083 13.1875 6.70142 12.3796 5.14955 10.8318C3.6533 9.33652 2.92517 8.36839 1.90611 6.51402C0.754861 4.42027 0.951111 3.32277 1.17049 2.8537C1.43174 2.29308 1.81736 1.95777 2.3158 1.62495C2.59891 1.43947 2.89851 1.28046 3.2108 1.14995C3.24205 1.13652 3.27111 1.1237 3.29705 1.11214C3.45174 1.04245 3.68611 0.937142 3.98299 1.04964C4.18111 1.12402 4.35799 1.2762 4.63486 1.54964C5.20267 2.10964 5.97861 3.35683 6.26486 3.96933C6.45705 4.38214 6.58424 4.65464 6.58455 4.96027C6.58455 5.31808 6.40455 5.59402 6.18611 5.89183C6.14517 5.94777 6.10455 6.0012 6.06517 6.05308C5.82736 6.36558 5.77517 6.45589 5.80955 6.61714C5.87924 6.9412 6.39892 7.90589 7.25299 8.75808C8.10705 9.61027 9.04392 10.0971 9.36924 10.1665C9.53736 10.2025 9.62955 10.1481 9.95205 9.90183C9.9983 9.86652 10.0458 9.82995 10.0955 9.79339C10.4286 9.54558 10.6917 9.37027 11.0411 9.37027H11.043C11.347 9.37027 11.6074 9.50214 12.0386 9.71964C12.6011 10.0034 13.8858 10.7693 14.4492 11.3378C14.7233 11.614 14.8761 11.7903 14.9508 11.9881C15.0633 12.2859 14.9574 12.5193 14.8883 12.6756C14.8767 12.7015 14.8639 12.73 14.8505 12.7615C14.7189 13.0733 14.559 13.3722 14.3727 13.6546C14.0405 14.1515 13.7039 14.5362 13.142 14.7978C12.8535 14.9342 12.5378 15.0034 12.2186 15Z" />
                    </g>
                </svg>
                <span>+66 (0) 2800 8000</span>
            </Phone>
            <Mail href="mailto:consumercenter@cpf.co.th" onClick={() => onClickTracker('email')} aria-label="Mail">
                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <g id="ion:mail">
                        <path id="Vector" d="M13.25 2.5H2.75C2.28602 2.5005 1.84119 2.68503 1.51311 3.01311C1.18503 3.34119 1.0005 3.78602 1 4.25V11.75C1.0005 12.214 1.18503 12.6588 1.51311 12.9869C1.84119 13.315 2.28602 13.4995 2.75 13.5H13.25C13.714 13.4995 14.1588 13.315 14.4869 12.9869C14.815 12.6588 14.9995 12.214 15 11.75V4.25C14.9995 3.78602 14.815 3.34119 14.4869 3.01311C14.1588 2.68503 13.714 2.5005 13.25 2.5ZM12.8069 5.39469L8.30688 8.89469C8.21912 8.9629 8.11115 8.99994 8 8.99994C7.88885 8.99994 7.78088 8.9629 7.69312 8.89469L3.19313 5.39469C3.14025 5.35476 3.09585 5.30472 3.06248 5.24749C3.02911 5.19025 3.00746 5.12695 2.99877 5.06127C2.99007 4.99559 2.99452 4.92884 3.01185 4.86489C3.02919 4.80094 3.05905 4.74108 3.09972 4.68878C3.14039 4.63647 3.19104 4.59277 3.24874 4.56021C3.30645 4.52766 3.37004 4.50689 3.43584 4.49913C3.50164 4.49136 3.56832 4.49675 3.63201 4.51498C3.69571 4.53321 3.75515 4.56391 3.80687 4.60531L8 7.86656L12.1931 4.60531C12.298 4.52613 12.4298 4.4914 12.56 4.50864C12.6903 4.52587 12.8085 4.59369 12.8892 4.69741C12.9698 4.80114 13.0064 4.93244 12.991 5.06293C12.9756 5.19341 12.9095 5.31259 12.8069 5.39469Z" />
                    </g>
                </svg>
                <span>consumercenter@cpf.co.th</span>
            </Mail>
        </Container>;
};
export default Contact;