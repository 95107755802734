import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { shared } from 'use-broadcast-ts';
import { create } from 'zustand';
import { GetTokenUsecase } from '@modules/auth/application/usecases/getToken.usecase';
import { RenewTokenUsecase } from '@modules/auth/application/usecases/renewToken.usecase';
import { RevokeTokenUsecase } from '@modules/auth/application/usecases/revokeToken.usecase';
import { IToken } from '@modules/auth/domain/model/auth.model';
import { loadingStore, messageStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { userStore } from '@modules/user/adapter/outbound/stores/user.store';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
import { StatusCodeType } from '@shared/types/statusCode.type';
import { getDomain } from '@shared/utils/cookie.utils';
import { Utag } from '@shared/utils/hooks/useUtag.utils';
import { AuthRepositoryImpl } from '../../inbound/repositories/auth.repository';
interface AuthState {
  getToken: (authCode: string, utag: Utag) => Promise<void>;
  logout: () => Promise<void>;
  renewToken: (isForce?: boolean) => Promise<void>;
  isRenewingToken: boolean;
}
export const authStore = create<AuthState>(shared((set, get) => ({
  getToken: async (authCode: string, utag: Utag) => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      if (get().isRenewingToken) return;
      const authRepositoryImpl = new AuthRepositoryImpl();
      const getTokenUsecase = new GetTokenUsecase(authRepositoryImpl);
      const tokens = await getTokenUsecase.handle(authCode);
      Cookies.set(getAuthCookiesName(), JSON.stringify(tokens), {
        expires: tokens.refreshExpiresIn,
        secure: true,
        sameSite: 'strict',
        domain: getDomain()
      });
      utag.link({
        tealium_event: 'login'
      });
      userStore.getState().fetchUserDetail();
    } catch (err) {
      console.error('error getToken', err);
      messageStore.setState({
        messageInfo: {
          text: err.message,
          status: 'error',
          duration: 2
        }
      });
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  },
  logout: async () => {
    try {
      if (get().isRenewingToken) return;
      const authRepositoryImpl = new AuthRepositoryImpl();
      const revokeTokenUsecase = new RevokeTokenUsecase(authRepositoryImpl);
      await revokeTokenUsecase.handle();
    } catch (err) {
      console.error('error logout', err);
    } finally {
      Cookies.remove(getAuthCookiesName(), {
        domain: getDomain()
      });
      userStore.getState().resetUser();
    }
  },
  isRenewingToken: false,
  renewToken: async isForce => {
    try {
      set({
        isRenewingToken: true
      });
      const cookies = Cookies.get(getAuthCookiesName());
      const {
        refreshExpiresIn,
        accessExpiresIn
      } = JSON.parse(cookies) as IToken;
      const expiredDate = dayjs(refreshExpiresIn);
      const isRefreshExpired = expiredDate.diff(new Date(), 'millisecond') < 0;
      if (isRefreshExpired) {
        get().logout();
      } else {
        const accessTokenExpiredDate = dayjs(accessExpiresIn);
        const isAccessTokenAlmostExpired = accessTokenExpiredDate.diff(new Date(), 'minutes') <= 3;
        if (isAccessTokenAlmostExpired || isForce) {
          const authRepositoryImpl = new AuthRepositoryImpl();
          const renewTokenUsecase = new RenewTokenUsecase(authRepositoryImpl);
          const tokens = await renewTokenUsecase.handle();
          Cookies.set(getAuthCookiesName(), JSON.stringify(tokens), {
            expires: tokens.refreshExpiresIn,
            secure: true,
            sameSite: 'strict',
            domain: getDomain()
          });
          userStore.getState().fetchUserDetail();
        }
      }
    } catch (err) {
      console.error('error renewToken', err);
      if ([StatusCodeType.Unauthorized, StatusCodeType.Forbidden, StatusCodeType.NotFound].includes(err.statusCode)) {
        get().logout();
      }
    } finally {
      set({
        isRenewingToken: false
      });
    }
  }
})));