import { FC } from 'react';
import { Checker, CheckerWrapper, Container, ImageStyle, ItemWrapper, Mask, Title } from './Item.style';
interface Props {
  title: string;
  imageUrl: string;
  isActive: boolean;
  onClick?: () => void;
}
const InterestContentItem: FC<Props> = ({
  title,
  imageUrl,
  isActive,
  onClick
}: Props) => {
  return <Container>
            <ItemWrapper $isActive={isActive} onClick={onClick}>
                <ImageStyle className="bg-image" width={114} height={114} alt="interesting image" src={imageUrl || '/images/default/brand-logo.png'} onError={({
        currentTarget
      }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = '/images/default/brand-logo.png';
      }} />
                <Mask />
                <Title>{title}</Title>
            </ItemWrapper>

            {isActive && <CheckerWrapper>
                    <Checker src="/icons/social/interest/check-icon.svg" alt="checker" width={200} height={200} />
                </CheckerWrapper>}
        </Container>;
};
export default InterestContentItem;