import { Builder } from 'builder-pattern';
import { match } from 'ts-pattern';
import { IFilterCategoryByKey, IFilterModuleType, IFilterType } from '@modules/filter/domain/models/filterCategory.model';
import { IGetFilterCategoryUsecase } from '@modules/filter/domain/ports/getFilterCategory.usecase.port';
import { ModuleType } from '@shared/types/module.type';
import { left, right } from '@shared/utils/either.utils';
import { IFilterCategoryRepository } from '../ports/filterCategory.repository.port';
export class GetFilterCategoryUsecase implements IGetFilterCategoryUsecase {
  constructor(private readonly filterCategoryRepository: IFilterCategoryRepository) {}
  async handle(): IGetFilterCategoryUsecase.getFilterCategoryOutput {
    const result = await this.filterCategoryRepository.getFilterCategory();
    const value = result.value;
    if (result.isLeft()) return left(value);
    const output = this._handleFilterCategory(value);
    return right(output);
  }
  _handleFilterCategory(data: IFilterType): IFilterModuleType {
    let filterModuleObj = Builder<IFilterCategoryByKey>().build();
    Object.entries(data).forEach(category => {
      const [key, value] = category;
      filterModuleObj = {
        ...filterModuleObj,
        [key]: {
          id: key,
          title: this._mapFilterCategoryTitle(key),
          items: value
        }
      };
    });
    return {
      [ModuleType.Recipe]: [filterModuleObj.meats, filterModuleObj.cooking, filterModuleObj.foodType, filterModuleObj.foodNationality],
      [ModuleType.Product]: [filterModuleObj.products, filterModuleObj.meats, filterModuleObj.brands, filterModuleObj.distributionChannel],
      [ModuleType.TipsTricks]: [filterModuleObj.tipsTiricks]
    };
  }
  _mapFilterCategoryTitle(key: string): string {
    return match(key).with('meats', () => 'ประเภทเนื้อสัตว์').with('cooking', () => 'วิธีการปรุง').with('foodType', () => 'ประเภทอาหาร').with('foodNationality', () => 'สัญชาติ').with('products', () => 'หมวดหมู่สินค้า').with('brands', () => 'แบรนด์สินค้า').with('distributionChannel', () => 'ช่องทางจำหน่ายสินค้า').with('tipsTiricks', () => 'ประเภทเคล็ดลับ / บทความ').otherwise(() => '');
  }
}