import { FC } from 'react';
import dayjs from 'dayjs';
import { Container } from './CopyrightFooter.style';
interface Props {
  onClickTracker: (title: string) => void;
}
const CopyrightFooter: FC<Props> = ({
  onClickTracker
}: Props) => {
  return <Container>
            © {dayjs().year()} บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน){' '}
            <a href="/privacy" target="_blank" aria-label="Privacy Policy" onClick={() => onClickTracker('privacy_policy')}>
                นโยบายความเป็นส่วนตัว
            </a>{' '}
            |{' '}
            <a href="/terms" target="_blank" aria-label="Terms and Conditions" onClick={() => onClickTracker('terms_conditions')}>
                ข้อกำหนดและเงื่อนไข
            </a>
        </Container>;
};
export default CopyrightFooter;