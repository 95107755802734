import { FC } from 'react';
import { Container, Content, ContentWrapper, LineMask, Wrapper } from './ContentModal.style';
import InterestContentItemLogic from './item/Item.logic';
import InterestStepperLogic from './stepper/Stepper.logic';
const InterestContentModal: FC = () => {
  return <Container>
            <InterestStepperLogic />
            <Wrapper>
                <LineMask />
                <ContentWrapper>
                    <Content>
                        <InterestContentItemLogic />
                    </Content>
                </ContentWrapper>
            </Wrapper>
        </Container>;
};
export default InterestContentModal;