import Image from 'next/image';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "HeadModalstyle__Container",
  componentId: "sc-1g8zj60-0"
})(["", " @media (max-width:", "){", "}@media (max-width:", "){", "}"], {
  "position": "absolute",
  "left": "50%",
  "top": "0px",
  "--tw-translate-x": "-50%",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "--tw-translate-y": "-50%",
  "padding": "0.5rem"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "85%"
}, ({
  theme
}) => theme.breakpoint.md, {
  "width": "100%"
});
export const Wrapper = styled.div.withConfig({
  displayName: "HeadModalstyle__Wrapper",
  componentId: "sc-1g8zj60-1"
})(["", " ", " @media (max-width:", "){", "}"], {
  "position": "relative",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center"
}, {
  "minHeight": "100px",
  "minWidth": "600px"
}, ({
  theme
}) => theme.breakpoint.md, {
  "minHeight": "90px",
  "minWidth": "0px"
});
export const ImageStyle = styled(Image).withConfig({
  displayName: "HeadModalstyle__ImageStyle",
  componentId: "sc-1g8zj60-2"
})(["", " ", ""], {
  "position": "absolute",
  "left": "0px",
  "top": "0px",
  "zIndex": "49"
}, {
  "height": "100%",
  "width": "100%"
});
export const Title = styled.span.withConfig({
  displayName: "HeadModalstyle__Title",
  componentId: "sc-1g8zj60-3"
})(["", " ", " @media (max-width:", "){", "}"], {
  "zIndex": "50",
  "fontSize": "2.25rem",
  "lineHeight": "2rem",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, {
  "display": "flex",
  "alignItems": "center"
}, ({
  theme
}) => theme.breakpoint.md, {
  "whiteSpace": "nowrap",
  "fontSize": "1.5rem",
  "lineHeight": "2rem"
});