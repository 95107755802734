import { Button, Modal } from 'antd';
import styled from 'styled-components';
export const CustomModal = styled(Modal).withConfig({
  displayName: "AlertModalstyle__CustomModal",
  componentId: "sc-18u5e4a-0"
})(["@media (max-width:", "){", "}"], ({
  theme
}) => theme.breakpoint.md, {
  "margin": "0px !important",
  "height": "100vh !important",
  "maxWidth": "100vw !important"
});
export const Wrapper = styled.div.withConfig({
  displayName: "AlertModalstyle__Wrapper",
  componentId: "sc-18u5e4a-1"
})(["", ""], {
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center"
});
export const Content = styled.div.withConfig({
  displayName: "AlertModalstyle__Content",
  componentId: "sc-18u5e4a-2"
})(["", " .image{", "}.title{", "}.description{", "}@media (max-width:", "){.image{", "}.title{", "}.description{", "}}"], {
  "height": "100%",
  "width": "100%",
  "textAlign": "center"
}, {
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginBottom": "2.75rem"
}, {
  "marginTop": "2rem",
  "fontSize": "45px",
  "fontWeight": "700",
  "lineHeight": "50px",
  "--tw-text-opacity": "1",
  "color": "rgb(43 43 43 / var(--tw-text-opacity))"
}, {
  "marginTop": "0.5rem",
  "fontSize": "28px",
  "fontWeight": "400",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(43 43 43 / var(--tw-text-opacity))"
}, ({
  theme
}) => theme.breakpoint.md, {
  "maxWidth": "222px"
}, {
  "marginTop": "46px",
  "fontSize": "2.25rem",
  "lineHeight": "2.5rem"
}, {
  "fontSize": "28px",
  "lineHeight": "30px"
});
export const BottomContent = styled.div.withConfig({
  displayName: "AlertModalstyle__BottomContent",
  componentId: "sc-18u5e4a-3"
})(["", " @media (max-width:", "){", "}"], {
  "marginTop": "59px"
}, ({
  theme
}) => theme.breakpoint.md, {
  "marginTop": "50px"
});
export const ButtonWrapper = styled.div.withConfig({
  displayName: "AlertModalstyle__ButtonWrapper",
  componentId: "sc-18u5e4a-4"
})(["", " @media (max-width:", "){", "}"], {
  "display": "flex",
  "width": "100%",
  "gap": "1.5rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "gap": "0.5rem"
});
export const CustomButton = styled(Button).withConfig({
  displayName: "AlertModalstyle__CustomButton",
  componentId: "sc-18u5e4a-5"
})(["", " &.ant-btn-default{", "}&.ant-btn-primary:hover{", "}@media (max-width:", "){", "}"], {
  "height": "auto",
  "borderWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(206 14 45 / var(--tw-border-opacity))",
  "paddingLeft": "2.5rem",
  "paddingRight": "2.5rem",
  "paddingTop": "0.375rem",
  "paddingBottom": "0.375rem",
  "fontSize": "28px",
  "lineHeight": "30px"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(206 14 45 / var(--tw-text-opacity))"
}, {
  "--tw-bg-opacity": "1 !important",
  "backgroundColor": "rgb(206 14 45 / var(--tw-bg-opacity)) !important"
}, ({
  theme
}) => theme.breakpoint.md, {
  "paddingLeft": "25px",
  "paddingRight": "25px"
});