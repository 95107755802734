import { FC, useEffect, useState } from 'react';
import { interestingStore } from '@modules/interesting/adapter/outbound/stores/interesting.store';
import { userStore } from '@modules/user/adapter/outbound/stores/user.store';
import useLockedBody from '@shared/utils/hooks/useLockedBody.utils';
import InterestModal from './InterestModal';
const InterestModalLogic: FC = () => {
  const [isInit, setIsInit] = useState(false);
  const [_locked, setLocked] = useLockedBody(false, 'root');
  const {
    isModalOpen,
    setIsModalOpen,
    updateShouldShowInteresting,
    fetchInterestStepList
  } = interestingStore(state => state);
  const {
    userDetail
  } = userStore(state => state);
  useEffect(() => {
    setIsInit(true);
  }, []);
  useEffect(() => {
    setLocked(isModalOpen);
  }, [isModalOpen]);
  useEffect(() => {
    if (isInit && userDetail?.shouldShowInteresting) {
      setIsModalOpen(true);
      updateShouldShowInteresting(false);
      fetchInterestStepList();
    }
  }, [isInit, userDetail]);
  return <InterestModal isModalOpen={isModalOpen} />;
};
export default InterestModalLogic;