import { jwtDecode } from 'jwt-decode';
import { IToken } from '@modules/auth/domain/model/auth.model';
import { CdpAuthResponse } from '@modules/libs/graphql/generated/graphql';
export class TokenEntityMapper {
  public static toDomain(authResponse: CdpAuthResponse): IToken {
    const decodedToken = jwtDecode(authResponse.accessToken) as any;
    return {
      jwt: authResponse.accessToken,
      accessExpiresIn: new Date(decodedToken.cdpAccessTokenExpireIn * 1000),
      refreshExpiresIn: new Date(decodedToken.cdpRefreshTokenExpireIn * 1000)
    };
  }
}