import { FC } from 'react';
import { Container } from './Node.style';
interface Props {
  title: string;
  isActive: boolean;
}
const StepperNode: FC<Props> = ({
  title,
  isActive
}: Props) => {
  return <Container $isActive={isActive}>
            <span>{title}</span>
        </Container>;
};
export default StepperNode;