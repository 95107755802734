import { shared } from 'use-broadcast-ts';
import { create } from 'zustand';
import { authStore } from '@modules/auth/adapter/outbound/stores/auth.store';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { GetUserDetailUsecase } from '@modules/user/application/usecases/getUserDetail.usecase';
import { IUser } from '@modules/user/domain/model/user.model';
import { UserRepositoryImpl } from '../../inbound/repositories/user.repository';
interface UserState {
  userDetail: IUser;
  fetchUserDetail: () => Promise<void>;
  resetUser: () => void;
}
export const userStore = create<UserState>(shared(set => ({
  userDetail: null,
  fetchUserDetail: async () => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const userRepositoryImpl = new UserRepositoryImpl();
      const getUserDetailUsecase = new GetUserDetailUsecase(userRepositoryImpl);
      const userDetail = await getUserDetailUsecase.handle();
      set({
        userDetail
      });
    } catch (err) {
      console.error('Error fetchUserDetail', err);
      authStore.getState().logout();
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  },
  resetUser: () => set({
    userDetail: null
  })
})));