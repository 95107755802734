import Cookies from 'js-cookie';
import { IToken } from '@modules/auth/domain/model/auth.model';
import { GetAllInterestingQueryDocument, GetChoosedInterestingContentQueryDocument, InterestingType, SaveChoosedInterestingMutationDocument, UpdateMemberInterestingRequest, UpdateShouldShowInterestingMutationDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlMutate, gqlQuery } from '@modules/libs/graphql/gql-config';
import { getAuthCookiesName } from '@shared/helpers/getAuthCookiesName.helper';
import { AllInterestingEntityMapper } from '../../../application/ports/interesting.mapper';
import { IInterestingRepository } from '../../outbound/ports/interesting.repository.port';
export class InterestingRepositoryImpl implements IInterestingRepository {
  async updateShouldShow(shouldShowInteresting: boolean): IInterestingRepository.updateShouldShowOutput {
    try {
      const cookies = Cookies.get(getAuthCookiesName());
      const tokens = cookies && JSON.parse(cookies) as IToken;
      const {
        errors
      } = await gqlMutate({
        mutation: UpdateShouldShowInterestingMutationDocument,
        headers: {
          authorization: tokens.jwt ? `Bearer ${tokens.jwt}` : ''
        },
        variables: {
          data: {
            shouldShowInteresting
          }
        }
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      return 'Success';
    } catch (error: any) {
      console.error('Error IInterestingRepositoryImpl.updateShouldShow', error);
      throw {
        data: error,
        message: error.message,
        statusCode: error.graphQLErrors[0].extensions.code
      };
    }
  }
  async getAllInteresting(): IInterestingRepository.getAll {
    try {
      const {
        data,
        errors
      } = await gqlQuery({
        query: GetAllInterestingQueryDocument
      });
      const {
        getMeatCategoryInteresting,
        getGroupCategoryMainInteresting,
        getRecipeTypeInteresting
      } = data;
      if (errors) throw errors[0].message ?? 'Unknow Error';
      const result = AllInterestingEntityMapper.toDomain({
        getMeatCategoryInteresting,
        getGroupCategoryMainInteresting,
        getRecipeTypeInteresting
      });
      return result;
    } catch (error: any) {
      console.error('Error IInterestingRepositoryImpl.getAllInteresting', error);
      throw {
        data: error,
        message: error.message,
        statusCode: error.graphQLErrors[0].extensions.code
      };
    }
  }
  async getChoosedInteresting(type: InterestingType): IInterestingRepository.getChoosed {
    try {
      const cookies = Cookies.get(getAuthCookiesName());
      const tokens = cookies && JSON.parse(cookies) as IToken;
      const {
        data,
        errors
      } = await gqlQuery({
        query: GetChoosedInterestingContentQueryDocument,
        headers: {
          authorization: tokens.jwt ? `Bearer ${tokens.jwt}` : ''
        },
        variables: {
          where: {
            tab: type
          }
        }
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      return data.getMemberInterestingResult;
    } catch (error: any) {
      console.error('Error IInterestingRepositoryImpl.getChoosedInterersting', error);
      throw {
        data: error,
        message: error.message,
        statusCode: error.graphQLErrors[0].extensions.code
      };
    }
  }
  async saveChoosedInteresting(request: UpdateMemberInterestingRequest): IInterestingRepository.save {
    try {
      const variables = {
        data: request
      };
      const cookies = Cookies.get(getAuthCookiesName());
      const tokens = cookies && JSON.parse(cookies) as IToken;
      const {
        errors
      } = await gqlMutate({
        mutation: SaveChoosedInterestingMutationDocument,
        headers: {
          authorization: tokens.jwt ? `Bearer ${tokens.jwt}` : ''
        },
        variables
      });
      if (errors) throw errors[0].message ?? 'Unknow Error';
      return 'Success';
    } catch (error: any) {
      console.error('Error IInterestingRepositoryImpl.saveChoosedInteresting', error);
      throw {
        data: error,
        message: error.message,
        statusCode: error.graphQLErrors[0].extensions.code
      };
    }
  }
}