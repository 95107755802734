import { IFilterCategoryRepository } from '@modules/filter/application/ports/filterCategory.repository.port';
import { GetFilterCategoryQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { left, right } from '@shared/utils/either.utils';
import { FilterCategoryEntityMapper } from '../../outbound/ports/filterCategory.mapper';
export class FilterCategoryRepositoryImpl implements IFilterCategoryRepository {
  async getFilterCategory(): IFilterCategoryRepository.getFilterCategoryOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: GetFilterCategoryQueryDocument
      });
      const output = FilterCategoryEntityMapper.toDomain(data);
      return right(output);
    } catch (error: any) {
      console.error('err FilterCategoryRepositoryImpl.getFilterCategory', error);
      return left(error);
    }
  }
}