import { FC } from 'react';
import { Skeleton } from 'antd';
import { isEmpty } from 'radash';
import { Container, ImageStyle, Title, Wrapper } from './HeadModal.style';
interface Props {
  title: string;
}
const InterestHeadModal: FC<Props> = ({
  title
}: Props) => {
  return <Container>
            <Wrapper>
                <ImageStyle src="/images/interesting/interesting-title.png" alt="interest title" width={560} height={64} />
                <Title>
                    {!isEmpty(title) ? `อะไรคือ${title}ที่ใช่สำหรับคุณ?` : <Skeleton.Input active size="small" />}
                </Title>
            </Wrapper>
        </Container>;
};
export default InterestHeadModal;