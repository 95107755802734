import { FC } from 'react';
import { Container, LeftText, NextButton } from './FooterModal.style';
interface Props {
  onClickNextStep: () => void;
  onClickSkip: () => void;
}
const InterestFooterModal: FC<Props> = ({
  onClickNextStep,
  onClickSkip
}: Props) => {
  return <Container>
            <LeftText onClick={onClickSkip}>เดี๋ยวกลับมาทำนะ</LeftText>
            <NextButton onClick={onClickNextStep} type="primary" danger>
                ถัดไป
            </NextButton>
        </Container>;
};
export default InterestFooterModal;