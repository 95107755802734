import { IInterestingRepository } from '@modules/interesting/adapter/outbound/ports/interesting.repository.port';
import { IInteresting } from '@modules/interesting/domain/models/interesting';
import { ISaveChoosedInterestingUsecase } from '@modules/interesting/domain/ports/saveChoosedInteresting.usecase.port';
import { UpdateMemberInterestingRequest } from '@modules/libs/graphql/generated/graphql';
import { mapInterestingTypeToQuery } from '@shared/helpers/mapInterestingType.helper';
export class SaveChoosedInterestingUsecase implements ISaveChoosedInterestingUsecase {
  constructor(private readonly interestingRepository: IInterestingRepository) {}
  async handle(interest: IInteresting): ISaveChoosedInterestingUsecase.output {
    try {
      const request: UpdateMemberInterestingRequest = {
        interestingType: mapInterestingTypeToQuery(interest.type),
        idOfInterestingItem: interest.content.filter(content => content.isChoosed).map(item => item.id)
      };
      return await this.interestingRepository.saveChoosedInteresting(request);
    } catch (err) {
      console.error('Error SaveChoosedInterestingUsecase.handle', err);
      throw err;
    }
  }
}