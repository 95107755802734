import { Modal } from 'antd';
import styled from 'styled-components';
export const ModalContainer = styled(Modal).withConfig({
  displayName: "InterestModalstyle__ModalContainer",
  componentId: "sc-p7aqt3-0"
})(["", " .ant-modal-content{", " box-shadow:0px 7px 16px 0px rgba(0,0,0,0.05) !important;}@media (max-width:", "){", "}@media (max-width:", "){.ant-modal-content{", "}}"], {
  "width": "fit-content",
  "minWidth": "900px !important",
  "paddingTop": "2.5rem",
  "paddingBottom": "2.5rem"
}, {
  "borderRadius": "1rem !important"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "100% !important",
  "minWidth": "0px !important"
}, ({
  theme
}) => theme.breakpoint.md, {
  "borderRadius": "0px !important",
  "backgroundColor": "transparent !important",
  "paddingLeft": "0.75rem !important",
  "paddingRight": "0.75rem !important",
  "--tw-shadow": "0 0 #0000 !important",
  "--tw-shadow-colored": "0 0 #0000 !important",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important"
});
export const MaskContainer = styled.div.withConfig({
  displayName: "InterestModalstyle__MaskContainer",
  componentId: "sc-p7aqt3-1"
})(["", " ", " display:", ";@media (max-width:", "){background-image:linear-gradient( 180deg,rgba(255,255,255,0.75),white );}"], {
  "position": "fixed",
  "left": "0px",
  "right": "0px",
  "top": "0px",
  "zIndex": "50",
  "minHeight": "100vh"
}, {
  "backgroundColor": "rgba(255, 255, 255, 0.75)",
  "--tw-backdrop-blur": "blur(10px)",
  "WebkitBackdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
  "backdropFilter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)"
}, ({
  $shouldOpen
}) => $shouldOpen ? 'block' : 'none', ({
  theme
}) => theme.breakpoint.md);