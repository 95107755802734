import { IInterestingRepository } from '@modules/interesting/adapter/outbound/ports/interesting.repository.port';
import { IInteresting } from '@modules/interesting/domain/models/interesting';
import { ISaveAllChoosedInterestingUsecase } from '@modules/interesting/domain/ports/saveAllChoosedInteresting.usecase.port';
import { UpdateMemberInterestingRequest } from '@modules/libs/graphql/generated/graphql';
import { mapInterestingTypeToQuery } from '@shared/helpers/mapInterestingType.helper';
export class SaveAllChoosedInterestingUsecase implements ISaveAllChoosedInterestingUsecase {
  constructor(private readonly interestingRepository: IInterestingRepository) {}
  async handle(interest: IInteresting[]): ISaveAllChoosedInterestingUsecase.output {
    try {
      const request: UpdateMemberInterestingRequest[] = interest.map(interestStep => {
        return {
          interestingType: mapInterestingTypeToQuery(interestStep.type),
          idOfInterestingItem: interestStep.content.filter(content => content.isChoosed).map(item => item.id)
        };
      });
      request.map(async request => {
        await this.interestingRepository.saveChoosedInteresting(request);
      });
      return 'Success';
    } catch (err) {
      console.error('Error SaveAllChoosedInterestingUsecase.handle', err);
      throw err;
    }
  }
}