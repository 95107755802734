import { ApolloClient, InMemoryCache } from '@apollo/client';
const gqlClient = (headers?: Record<string, string>) => {
  return new ApolloClient({
    uri: process.env.GRAPHQL_API_ENDPOINT,
    cache: new InMemoryCache(),
    headers: {
      ...headers,
      'x-api-key': process.env.X_API_KEY
    }
  });
};
export default gqlClient;