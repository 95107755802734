import { Key } from 'react';
import { Builder } from 'builder-pattern';
import { unique } from 'radash';
import { match } from 'ts-pattern';
import { create } from 'zustand';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { GetFilterCategoryUsecase } from '@modules/filter/application/usecases/getFilterCategory.usecase';
import { IFilterCategory, IFilterModuleType, ISelectedFilterItem } from '@modules/filter/domain/models/filterCategory.model';
import { ModuleType } from '@shared/types/module.type';
import { FilterCategoryRepositoryImpl } from '../../inbound/repositories/filterCategory.repositories';
interface FilterState {
  recipeFilterCategory: IFilterCategory[];
  productFilterCategory: IFilterCategory[];
  tipsTricksFilterCategory: IFilterCategory[];
  defaultFilterCategories: IFilterModuleType;
  selectedRecipeCategoryItem: ISelectedFilterItem[];
  selectedProductCategoryItem: ISelectedFilterItem[];
  selectedTipsTricksCategoryItem: ISelectedFilterItem[];
  fetchFilterCategory: () => void;
  onSelectedCategoryItem: (activeTab: ModuleType, categoryId: string, itemId: Key, isRemoveItem: boolean) => void;
  clearAllSelectedCategoryItem: (activeTab: ModuleType) => void;
}
export const filterStore = create<FilterState>((set, get) => ({
  recipeFilterCategory: [],
  productFilterCategory: [],
  tipsTricksFilterCategory: [],
  defaultFilterCategories: Builder<IFilterModuleType>().build(),
  selectedRecipeCategoryItem: [],
  selectedProductCategoryItem: [],
  selectedTipsTricksCategoryItem: [],
  fetchFilterCategory: async (): Promise<void> => {
    try {
      loadingStore.setState({
        isLoading: true
      });
      const filterCategoryRepoImpl = new FilterCategoryRepositoryImpl();
      const getFilterCategoryUsecase = new GetFilterCategoryUsecase(filterCategoryRepoImpl);
      const result = await getFilterCategoryUsecase.handle();
      if (result.isRight()) {
        const {
          Recipe,
          Product,
          TipsTrick
        } = result.value;
        set(() => ({
          recipeFilterCategory: Recipe,
          productFilterCategory: Product,
          tipsTricksFilterCategory: TipsTrick,
          defaultFilterCategories: {
            Recipe,
            Product,
            TipsTrick
          }
        }));
      }
    } catch (err) {
      console.error('Error fetchFilterCategory ', err);
    } finally {
      loadingStore.setState({
        isLoading: false
      });
    }
  },
  onSelectedCategoryItem: (activeTab: ModuleType, categoryId: string, itemId: Key, isRemoveItem: boolean) => {
    const selectedFilterItemsKey = match(activeTab).with(ModuleType.Recipe, () => {
      return 'selectedRecipeCategoryItem';
    }).with(ModuleType.Product, () => {
      return 'selectedProductCategoryItem';
    }).with(ModuleType.TipsTricks, () => {
      return 'selectedTipsTricksCategoryItem';
    }).otherwise(() => null);
    const id = `${categoryId}-${itemId}`;
    set(() => ({
      [selectedFilterItemsKey]: isRemoveItem ? get()[selectedFilterItemsKey].filter((item: ISelectedFilterItem) => item.id !== id) : unique([...get()[selectedFilterItemsKey], {
        id,
        categoryId,
        itemId,
        title: ''
      }], item => item.id)
    }));
  },
  clearAllSelectedCategoryItem: (activeTab: ModuleType) => {
    match(activeTab).with(ModuleType.Recipe, () => {
      set(() => ({
        selectedRecipeCategoryItem: []
      }));
    }).with(ModuleType.Product, () => {
      set(() => ({
        selectedProductCategoryItem: []
      }));
    }).with(ModuleType.TipsTricks, () => {
      set(() => ({
        selectedTipsTricksCategoryItem: []
      }));
    }).otherwise(() => {});
  }
}));