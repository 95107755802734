import { FC } from 'react';
import { isEmpty } from 'radash';
import { loadingStore } from '@modules/core/adapter/outbound/stores/ui.store';
import { interestingStore } from '@modules/interesting/adapter/outbound/stores/interesting.store';
import InterestingItemSkeleton from '@components/skeleton/interesting/InterestingContent';
import InterestContentItem from './Item';
const InterestContentItemLogic: FC = () => {
  const {
    interestStep,
    setChoosedContent
  } = interestingStore(state => state);
  const {
    isLoading
  } = loadingStore(state => state);
  return !isEmpty(interestStep?.content) && !isLoading ? interestStep.content.map(item => <InterestContentItem key={item.id} imageUrl={item.imageUrl} title={item.title} isActive={item.isChoosed} onClick={() => setChoosedContent(item.id)} />) : <InterestingItemSkeleton />;
};
export default InterestContentItemLogic;