import Image from 'next/image';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Socialstyle__Container",
  componentId: "sc-ggwj0g-0"
})([""]);
export const Title = styled.div.withConfig({
  displayName: "Socialstyle__Title",
  componentId: "sc-ggwj0g-1"
})(["", " @media (max-width:", "){", "}"], {
  "marginBottom": "0.25rem",
  "fontSize": "1.5rem",
  "lineHeight": "2rem",
  "fontWeight": "700",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "marginBottom": "0.5rem",
  "fontSize": "28px",
  "lineHeight": "30px"
});
export const SocialWrapper = styled.div.withConfig({
  displayName: "Socialstyle__SocialWrapper",
  componentId: "sc-ggwj0g-2"
})(["", " svg{", " fill:", ";transition:all 0.1s ease-in;&:hover{fill:", ";transition:all 0.1s ease-in;}@media (max-width:", "){", " &:hover{fill:", ";}}}"], {
  "display": "flex",
  "alignItems": "center",
  "gap": "1rem"
}, {
  "height": "2rem !important",
  "width": "2rem !important"
}, ({
  theme
}) => theme.colors.grey[20], ({
  theme
}) => theme.colors.orange[50], ({
  theme
}) => theme.breakpoint.lg, {
  "height": "2.5rem !important",
  "width": "2.5rem !important"
}, ({
  theme
}) => theme.colors.grey[20]);
export const ImageSocialIcon = styled(Image).withConfig({
  displayName: "Socialstyle__ImageSocialIcon",
  componentId: "sc-ggwj0g-3"
})(["", " @media (max-width:", "){", "}"], {
  "height": "2rem !important",
  "width": "2rem !important"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "height": "2.5rem !important",
  "width": "2.5rem !important"
});