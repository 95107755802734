import { FC } from 'react';
import { interestingStore } from '@modules/interesting/adapter/outbound/stores/interesting.store';
import InterestFooterModal from './FooterModal';
const InterestFooterModalLogic: FC = () => {
  const {
    setIsSkip,
    save
  } = interestingStore(state => state);
  return <InterestFooterModal onClickSkip={() => setIsSkip(true)} onClickNextStep={save} />;
};
export default InterestFooterModalLogic;