import styled from 'styled-components';
import theme from '@shared/assets/theme/globalTheme';
export const Container = styled.div.withConfig({
  displayName: "Linestyle__Container",
  componentId: "sc-tbs8x7-0"
})(["", " background-color:", ";@media (max-width:", "){", "}"], {
  "marginLeft": "-0.125rem",
  "marginRight": "-0.125rem",
  "height": "0.375rem",
  "width": "166px",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, ({
  $isActive
}) => $isActive ? theme.colors.primary[50] : 'rgba(229, 229, 229, 1)', ({
  theme
}) => theme.breakpoint.md, {
  "width": "82px"
});