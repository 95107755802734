import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Stepperstyle__Container",
  componentId: "sc-1o9ojw5-0"
})(["", " .ant-skeleton-input{", "}"], {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center"
}, {
  "marginLeft": "auto !important",
  "marginRight": "auto !important",
  "width": "300px !important"
});