import { IAllInteresting, IInteresting, IInterestingContent } from '@modules/interesting/domain/models/interesting';
import { GroupCategoryResponse, MeatCategoryResponse, RecipeTypeResponse } from '@modules/libs/graphql/generated/graphql';
export class AllInterestingEntityMapper {
  public static toDomain({
    getMeatCategoryInteresting,
    getGroupCategoryMainInteresting,
    getRecipeTypeInteresting
  }): IAllInteresting {
    return {
      groupCategoryMain: getGroupCategoryMainInteresting.map((group: GroupCategoryResponse) => this.groupCategoryMainMapper(group)),
      meatCategory: getMeatCategoryInteresting.map((category: MeatCategoryResponse) => this.meatCategoryMapper(category)),
      recipeType: getRecipeTypeInteresting.map((type: RecipeTypeResponse) => this.recipeTypeMapper(type))
    };
  }
  private static groupCategoryMainMapper(group: GroupCategoryResponse): IInterestingContent {
    return {
      id: group.id,
      title: group.title,
      imageUrl: group.interestingCoverUrl,
      isChoosed: false
    };
  }
  private static meatCategoryMapper(meatCategory: MeatCategoryResponse): IInterestingContent {
    return {
      id: meatCategory.id,
      title: meatCategory.title,
      imageUrl: meatCategory.interestingCoverUrl,
      isChoosed: false
    };
  }
  private static recipeTypeMapper(type: RecipeTypeResponse): IInterestingContent {
    return {
      id: type.id,
      title: type.title,
      imageUrl: type.interestingCoverUrl,
      isChoosed: false
    };
  }
}