import { FC, Fragment } from 'react';
import { interestingStore } from '@modules/interesting/adapter/outbound/stores/interesting.store';
import { SkipModalLogic, SuccessModalLogic } from '@components/interesting/modal/alert';
import InterestModalLogic from '@components/modal/interest/InterestModal.logic';
const InterestProvider: FC = () => {
  const {
    isSkip,
    isSuccess
  } = interestingStore(state => state);
  return <Fragment>
            <InterestModalLogic />
            <SkipModalLogic isOpen={isSkip} />
            <SuccessModalLogic isOpen={isSuccess} />
        </Fragment>;
};
export default InterestProvider;