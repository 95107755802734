import { FC } from 'react';
import { Container } from './Line.style';
interface Props {
  isActive: boolean;
}
const StepperLine: FC<Props> = ({
  isActive
}: Props) => {
  return <Container $isActive={isActive} />;
};
export default StepperLine;