import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "CopyrightFooterstyle__Container",
  componentId: "sc-1rjn8y0-0"
})(["", " a:hover{", "}@media (max-width:", "){", "}@media (max-width:", "){a:hover{", "}}@media (max-width:", "){", "}"], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(23 23 23 / var(--tw-bg-opacity))",
  "paddingTop": "1.5rem",
  "paddingBottom": "1.5rem",
  "textAlign": "center",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity))"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(238 132 60 / var(--tw-text-opacity))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "paddingTop": "1rem",
  "paddingBottom": "1rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(23 23 23 / var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity))"
}, ({
  theme
}) => theme.breakpoint.sm, {
  "fontSize": "0.75rem",
  "lineHeight": "1rem"
});