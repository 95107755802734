import { ObjectValueType } from '@shared/utils/type.util';
export const StatusCodeType = {
  OK: 200,
  Created: 201,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  InternalServerError: 500,
  BadGateway: 502,
  GatewayTimeout: 504
} as const;
export type StatusCodeType = ObjectValueType<typeof StatusCodeType>;