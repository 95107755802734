import Image from 'next/image';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "Itemstyle__Container",
  componentId: "sc-1fezki2-0"
})(["", ""], {
  "position": "relative",
  "marginLeft": "auto",
  "marginRight": "auto",
  "height": "fit-content",
  "width": "fit-content"
});
export const ItemWrapper = styled.div.withConfig({
  displayName: "Itemstyle__ItemWrapper",
  componentId: "sc-1fezki2-1"
})(["", " ", " outline:", ";&:hover{.bg-image{", " @media (max-width:", "){", "}}}@media (max-width:", "){", "}"], {
  "position": "relative",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center"
}, {
  "margin": "0.375rem",
  "aspectRatio": "1 / 1",
  "width": "114px",
  "cursor": "pointer",
  "overflow": "hidden",
  "borderRadius": "9999px"
}, ({
  $isActive
}) => $isActive ? '6px solid #29CC6A' : '0', {
  "--tw-scale-x": "1.25",
  "--tw-scale-y": "1.25",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "transform",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "90px"
});
export const ImageStyle = styled(Image).withConfig({
  displayName: "Itemstyle__ImageStyle",
  componentId: "sc-1fezki2-2"
})(["", " ", ""], {
  "position": "absolute",
  "left": "0px",
  "right": "0px",
  "top": "0px",
  "zIndex": "48"
}, {
  "height": "100%",
  "width": "100%",
  "objectFit": "cover",
  "transitionProperty": "transform",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
});
export const CheckerWrapper = styled.div.withConfig({
  displayName: "Itemstyle__CheckerWrapper",
  componentId: "sc-1fezki2-3"
})(["", " ", " ", " @media (max-width:", "){", "}"], {
  "position": "absolute",
  "bottom": "0.5rem",
  "right": "0.5rem",
  "zIndex": "49"
}, {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center"
}, {
  "aspectRatio": "1 / 1",
  "width": "1.75rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(41 204 106 / var(--tw-bg-opacity))",
  "outlineStyle": "solid",
  "outlineWidth": "3px",
  "outlineColor": "#fff"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "1.25rem"
});
export const Checker = styled(Image).withConfig({
  displayName: "Itemstyle__Checker",
  componentId: "sc-1fezki2-4"
})(["", " @media (max-width:", "){", "}"], {
  "aspectRatio": "1 / 1",
  "height": "1rem",
  "width": "1rem"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "0.75rem"
});
export const Title = styled.div.withConfig({
  displayName: "Itemstyle__Title",
  componentId: "sc-1fezki2-5"
})(["", " ", " ", " @media (max-width:", "){", "}"], {
  "position": "relative",
  "zIndex": "49",
  "maxWidth": "100px",
  "textTransform": "uppercase"
}, {
  "whiteSpace": "break-spaces",
  "textAlign": "center",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, {
  "fontSize": "1rem",
  "lineHeight": "18px",
  "fontWeight": "700"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "width": "80%"
});
export const Mask = styled.div.withConfig({
  displayName: "Itemstyle__Mask",
  componentId: "sc-1fezki2-6"
})(["", " ", ""], {
  "position": "absolute",
  "left": "0px",
  "right": "0px",
  "top": "0px",
  "zIndex": "49"
}, {
  "height": "100%",
  "width": "100%",
  "backgroundColor": "rgba(27, 27, 27, 0.4)"
});