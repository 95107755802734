import { List } from 'antd';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "FooterDesktopstyle__Container",
  componentId: "sc-1g1vvjt-0"
})(["", " a:hover{", "}"], {
  "zIndex": "20",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(23 23 23 / var(--tw-bg-opacity))"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(238 132 60 / var(--tw-text-opacity))"
});
export const Wrapper = styled.div.withConfig({
  displayName: "FooterDesktopstyle__Wrapper",
  componentId: "sc-1g1vvjt-1"
})(["", " max-width:", ";.ant-row{> div{", "}> div:last-child{", "}> div:not(:last-child){", "}}.ant-col{", "}.second-col,.third-col{.ant-list-item{", "}}.quart-col{.ant-list-item{", " a{", " &:hover{", "}}}}"], {
  "position": "relative",
  "marginLeft": "auto",
  "marginRight": "auto",
  "padding": "0px",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))",
  "@media (min-width: 767px)": {
    "paddingTop": "4rem",
    "paddingBottom": "4rem"
  }
}, ({
  theme
}) => `${theme.layout.width}`, {
  "margin": "0px",
  "paddingLeft": "1.25rem",
  "paddingRight": "1.25rem",
  "paddingTop": "0.625rem",
  "paddingBottom": "0.625rem",
  "@media (min-width: 767px)": {
    "padding": "0px"
  }
}, {
  "marginBottom": "0.75rem",
  "@media (min-width: 767px)": {
    "marginBottom": "0px"
  }
}, {
  "borderBottomWidth": "1px",
  "borderStyle": "solid",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(255 255 255 / var(--tw-border-opacity))",
  "@media (min-width: 767px)": {
    "borderWidth": "0px"
  }
}, {
  "padding": "0.625rem",
  "@media (min-width: 767px)": {
    "padding": "5rem"
  }
}, {
  "fontSize": "1rem",
  "lineHeight": "1.5rem"
}, {
  "fontSize": "1.25rem",
  "lineHeight": "1.75rem",
  "fontWeight": "700"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(238 132 60 / var(--tw-text-opacity))"
});
export const CustomList = styled(List).withConfig({
  displayName: "FooterDesktopstyle__CustomList",
  componentId: "sc-1g1vvjt-2"
})(["div{", "}.ant-list-header{", "}.ant-list-item{", "}"], {
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, {
  "marginLeft": "0px",
  "marginRight": "0px",
  "marginBottom": "0.375rem",
  "marginTop": "0px",
  "padding": "0px",
  "fontSize": "1.25rem",
  "lineHeight": "1.75rem"
}, {
  "margin": "0px",
  "padding": "0px"
});
export const CustomListItem = styled(List.Item).withConfig({
  displayName: "FooterDesktopstyle__CustomListItem",
  componentId: "sc-1g1vvjt-3"
})(["", " a,p{", " img{", "}}"], {
  "margin": "0px",
  "padding": "0px"
}, {
  "--tw-text-opacity": "1",
  "color": "rgb(206 206 206 / var(--tw-text-opacity))"
}, {
  "marginRight": "0.25rem",
  "display": "inline-block"
});