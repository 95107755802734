import { FC, Fragment } from 'react';
import { Skeleton } from 'antd';
import { IInteresting } from '@modules/interesting/domain/models/interesting';
import StepperLine from './line/Line';
import StepperNode from './node/Node';
import { Container } from './Stepper.style';
interface Props {
  stepList: IInteresting[];
  activeStep: number;
}
const InterestStepper: FC<Props> = ({
  stepList,
  activeStep
}: Props) => {
  return <Container>
            {stepList ? stepList.map((step, index) => <Fragment key={step.id}>
                        <StepperNode title={step.title} isActive={step.id <= activeStep} />
                        {stepList.length !== index + 1 && <StepperLine isActive={step.id < activeStep} />}
                    </Fragment>) : <Skeleton.Input active />}
        </Container>;
};
export default InterestStepper;