import { IFilterItem, IFilterType } from '@modules/filter/domain/models/filterCategory.model';
import { BrandResponse } from '@modules/libs/graphql/generated/graphql';
export class FilterCategoryEntityMapper {
  public static toDomain(filterCategory): IFilterType {
    return {
      meats: this.filterItemMapper(filterCategory.getAllFilterMeatCategory),
      cooking: this.filterItemMapper(filterCategory.getAllFilterRecipeMethod),
      foodType: this.filterItemMapper(filterCategory.getAllFilterRecipeType),
      foodNationality: this.filterItemMapper(filterCategory.getAllFilterRecipeRegion),
      products: this.filterItemMapper(filterCategory.getAllProductCategory),
      brands: this.filterBrandItemMapper(filterCategory.getAllBrand),
      distributionChannel: this.filterItemMapper(filterCategory.getAllBranchFilter),
      tipsTiricks: this.filterItemMapper(filterCategory.getAllFilterTipsTricksCategory)
    };
  }
  private static filterItemMapper(items): IFilterItem[] {
    return items.map((items): IFilterItem => ({
      id: items.id,
      title: items.title ?? '',
      imageUrl: items.imageUrl ?? items.logo ?? ''
    }));
  }
  private static filterBrandItemMapper(items: BrandResponse[]): IFilterItem[] {
    return items.map((items): IFilterItem => ({
      id: items.brandId,
      title: items.title ?? '',
      imageUrl: items.thumbnailUrl ?? ''
    }));
  }
}