import { Layout } from 'antd';
import styled from 'styled-components';
export const CustomLayout = styled(Layout).withConfig({
  displayName: "Layoutstyle__CustomLayout",
  componentId: "sc-1y0f9al-0"
})(["", " @media (max-width:", "){", "}"], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
  "paddingTop": "72px"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "paddingTop": "3.5rem"
});
export const CustomContainer = styled.div.withConfig({
  displayName: "Layoutstyle__CustomContainer",
  componentId: "sc-1y0f9al-1"
})(["", ""], {
  "width": "100%",
  "backgroundColor": "transparent",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
export const Wrapper = styled.div.withConfig({
  displayName: "Layoutstyle__Wrapper",
  componentId: "sc-1y0f9al-2"
})(["", " max-width:", ";"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "overflowWrap": "break-word"
}, "1140px");