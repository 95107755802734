import { FC } from 'react';
import { ConfigProvider } from 'antd';
import InterestContentModal from './content/ContentModal';
import InterestFooterModalLogic from './footer/FooterModal.logic';
import InterestHeadModalLogic from './head/HeadModal.logic';
import { MaskContainer, ModalContainer } from './InterestModal.style';
interface Props {
  isModalOpen: boolean;
}
const InterestModal: FC<Props> = ({
  isModalOpen
}: Props) => {
  return <ConfigProvider theme={{
    token: {
      motion: false
    }
  }}>
            <MaskContainer $shouldOpen={isModalOpen}>
                <ModalContainer title={<InterestHeadModalLogic />} open={isModalOpen} centered keyboard={false} mask={false} maskClosable={false} zIndex={50} closeIcon={false} footer={() => <InterestFooterModalLogic />}>
                    <InterestContentModal />
                </ModalContainer>
            </MaskContainer>
        </ConfigProvider>;
};
export default InterestModal;