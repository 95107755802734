import React, { FC, ReactNode } from 'react';
import { interestingStore } from '@modules/interesting/adapter/outbound/stores/interesting.store';
import InterestStepper from './Stepper';
const InterestStepperLogic: FC = () => {
  const {
    interestStepList,
    activeStep
  } = interestingStore(state => state);
  return <InterestStepper stepList={interestStepList} activeStep={activeStep} />;
};
export default InterestStepperLogic;